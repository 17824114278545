
//default
$listview-font-family: $font-family !default;
$listview-text-color: $grey-dark-font !default;
$listview-text-disabled: $grey-dark-font !default;
$listview-icon-color: $grey-dark-font !default;
$listview-icon-disabled: $grey-dark-font !default;
$listview-icon-margin-right: 16px !default;
$listview-icon-back-margin: 2px !default;
$listview-rtl-icon-back-margin: -2px !default;
$listview-icon-line-height: 34px !default;
$listview-icon-collapsible: '\e913' !default;
$listview-icon-collapsible-rtl: '\e839' !default;
$listview-icon-back-rtl: '\e860' !default;
$listview-back-icon: '\e85b' !default;
$listview-background: $grey-dark !default;
$listview-line-color: $grey-white !default;
$listview-item-hover-bg: $grey-800 !default;
$listview-root-border-color: none;
$listview-root-border-radius: 0;
$listview-nested-icon-collapsible: 12px;
$listview-item-height: 36px !default;
$listview-item-line-height: 36px !default;
$listview-item-padding: 0 16px !default;
$listview-text-hover-color: $grey-dark-font !default;
$listview-item-active-bg: $grey-700 !default;
$listview-text-active-color: $grey-dark-font !default;
$listview-header-text-color: rgba($grey-dark-font, .7) !default;
$listview-header-text-padding: 16px !default;
$listview-header-text-disabled: $grey-dark-font !default;
$listview-header-bg: $grey-800 !default;
$listview-header-icon-color: $grey-dark-font !default;
$listview-header-icon-disabled: $grey-dark-font !default;
$listview-header-border: rgba($grey-black, .12) !default;
$listview-header-font-size: 20px !default;
$listview-header-height: 48px !default;
$listview-header-font-weight: bold !default;
$listview-header-line-height: 15px !default;
$listview-header-padding-bottom: 0 !default;
$listview-touch-back-icon-padding: 0 !default;
$checkmark-bgcolor: $accent !default;
$checkmark-border-color: transparent !default;
$checkmark-color: $accent-font !default;
$listview-border-bottom: 0 !default;
$listview-border-top: 0 !default;
$listview-border-left: 0 !default;
$listview-border-right: 0 !default;
$listview-hover-border-color: transparent !default;
$listview-border-bottom-color: transparent !default;
$listview-border-top-color: transparent !default;
$listview-border-right-color: transparent !default;
$listview-border-left-color: transparent !default;
$listview-group-first-border-bottom: 0 !default;
$listview-group-border-top: 1px !default;
$listview-group-border-bottom: 0 !default;
$listview-groupheader-text-color: rgba($grey-dark-font, .7) !default;
$listview-groupheader-bg: $grey-800 !default;
$listview-groupheader-border: $grey-white !default;
$listview-groupheader-icon: $grey-dark-font !default;
$listview-groupheader-item-height: 36px !default;
$listview-groupheader-item-line-height: 36px !default;
$listview-groupheader-font-size: 15px !default;
$listview-border-size: 0 !default;
$listview-font-size: 15px !default;
$listview-back-padding-right: 30px !default;
$listview-back-icon-height: 1px !default;
$listview-back-icon-font-size: auto !default;
$listview-touch-item-height: 48px !default;
$listview-touch-item-line-height: 45px !default;
$listview-touch-item-font-size: 15px !default;
$listview-touch-groupheader-height: 48px !default;
$listview-touch-groupheader-line-height: 45px !default;
$listview-touch-header-height: 64px !default;
$listview-touch-header-font-weight: bold !default;
$listview-touch-header-font-size: 20px !default;
$listview-touch-header-line-height: 30px !default;
$listview-touch-header-icon-margin-top: 2px !default;
$listview-checkbox-right-margin: -2px 0 0 10px !default;
$listview-checkbox-left-margin: -2px 10px 0 0 !default;
$listview-rtl-checkbox-left-margin: -2px 0 0 10px !default;
$listview-rtl-checkbox-right-margin: -2px 10px 0 0 !default;

//ListView In-built template variables
$listview-template-padding: .2667em 1.0666em !default;
$listview-template-avatar-padding-right: 1.0666em !default;
$listview-template-avatar-padding-left: 4.8em !default;
$listview-template-avatar-rightposition-padding-right: 4.8em !default;
$listview-template-avatar-rightposition-padding-left: 1.0666em !default;
$listview-template-avatar-size: 2.6667em !default;
$listview-template-avatar-top: .2666em !default;
$listview-template-avatar-left: 1.0667em !default;
$listview-template-avatar-rightposition-right: 1.0667em !default;
$listview-template-badge-height: 1.6666em !default;
$listview-template-badge-width: 2.5em !default;
$listview-template-badge-line-height: 1.8666em !default;
$listview-template-badge-font-size: 12px !default;
$listview-template-badge-right: 1.33em !default;
$listview-template-avatar-badge-padding-right: 4.1333em !default;
$listview-template-avatar-badge-padding-left: 4.8em !default;
$listview-template-badgewithoutavatar-padding-right: 4.1333em !default;
$listview-template-badgewithoutavatar-padding-left: 1.0666em !default;
$listview-template-item-padding: .72em 0 !default;
$listview-template-multiline-header-color: rgba($grey-light-font, .87) !default;
$listview-template-multiline-content-color: rgba($grey-light-font, .54) !default;
$listview-template-multiline-header-padding: .115em 0 !default;
$listview-template-multiline-content-padding: .115em 0 !default;
$listview-template-multiline-header-font-size: 15px !default;
$listview-template-multiline-padding: 1.0666em !default;
$listview-template-multiline-content-font-size: 13px !default;
$listview-template-multiline-avatar-top: 1.0666em !default;

//enddefault

@include export-module('listview-layout') {
  /* stylelint-disable property-no-vendor-prefix */
  .e-bigger #{&}.e-listview,
  #{&}.e-listview.e-bigger {
    @if ($skin-name == 'bootstrap4') {
      font-size: 16px;
    }

    .e-list-item {
      border-bottom: $listview-border-bottom solid $listview-border-bottom-color;
      border-left: $listview-border-left solid $listview-border-left-color;
      border-right: $listview-border-right solid $listview-border-right-color;
      border-top: $listview-border-top solid $listview-border-top-color;
      @if ($skin-name == 'tailwind') {
        .e-text-content .e-list-text {
          font-size: $listview-touch-item-font-size;
        }
      }
    }

    &:not(.e-list-template) .e-list-item {
      height: $listview-touch-item-height;
      line-height: $listview-touch-item-line-height;
      position: relative;
      @if ($skin-name == 'bootstrap4') {
        padding: 12px 20px;
      }
      @if ($skin-name == 'tailwind') {
        padding: $listview-touch-item-padding;
      }
    }

    .e-text-content {
      font-size: $listview-touch-item-font-size;
    }

    .e-list-group-item {
      height: $listview-touch-groupheader-height;
      line-height: $listview-touch-groupheader-line-height;
      @if ($skin-name == 'bootstrap4') {
        font-size: 16px;
        padding: 12px 20px;
      }
      @if ($skin-name == 'tailwind' or $skin-name == 'FluentUI') {
        padding: $listview-template-padding;
      }
      @if ($skin-name == 'FluentUI') {
        font-size: 16px;
      }

      .e-text-content {
        @if ($skin-name == 'tailwind') {
          font-size: $listview-touch-header-font-size;
          line-height: $listview-touch-groupheader-line-height;
        }
      }
    }

    .e-list-header {
      align-items: center;
      display: flex;
      font-weight: $listview-touch-header-font-weight;
      height: $listview-touch-header-height;
      @if ($skin-name == 'bootstrap4') {
        font-size: 20px;
        line-height: 1.2;
        padding: 0 0 0 20px;
      }
      @if ($skin-name == 'tailwind' or $skin-name == 'FluentUI') {
        font-size: $text-sm;
        line-height: $listview-item-line-height;
        padding: $listview-template-padding;
      }
    }

    .e-list-header .e-text.header {
      display: none;
    }

    .e-list-header .e-headertemplate-text.nested-header {
      display: none;
    }

    .e-list-header .e-text {
      font-size: $listview-touch-header-font-size;
    }

    .e-back-button {
      @if ($skin-name == 'bootstrap4') {
        margin-top: -3px;
        padding-right: 12px;
      }
      @if ($skin-name == 'tailwind') {
        margin-right: $listview-touch-icon-margin-right;
      }
    }

    .e-list-icon {
      @if ($skin-name == 'bootstrap4') {
        margin-right: 12px;
      }
      @if ($skin-name == 'tailwind') {
        height: $listview-touch-item-size;
        margin-right: $listview-touch-icon-margin-right;
        width: $listview-touch-item-size;
      }
    }

    .e-icon-collapsible {
      @if ($skin-name == 'bootstrap4') {
        font-size: 12px;
      }
      @if ($skin-name == 'tailwind') {
        font-size: $listview-touch-item-size;
        height: $listview-touch-item-size;
        width: $listview-touch-item-size;
      }
    }

    .e-listview-checkbox {
      &.e-checkbox-left {
        @if ($skin-name == 'bootstrap4') {
          margin: 0 12px 0 0;
        }
        @if ($skin-name == 'tailwind' or $skin-name == 'FluentUI') {
          margin: $listview-touch-checkbox-left-margin;
        }
        @if ($skin-name == 'FluentUI') {
          border: 0;
          padding: 0;
        }
      }

      &.e-checkbox-right {
        @if ($skin-name == 'bootstrap4') {
          margin: 0 0 0 12px;
        }
        @if ($skin-name == 'tailwind') {
          margin: $listview-touch-checkbox-right-margin;
        }
      }
    }

    &.e-rtl {
      @if ($skin-name == 'FluentUI') {
        &.e-list-template {
          .e-list-wrapper {
            &.e-list-avatar {
              .e-avatar {
                margin: $listview-template-avatar-rtl-margin;
              }
            }
          }
        }
      }
      @if ($skin-name == 'tailwind') {
        &:not(.e-list-template) .e-list-item {
          padding: $listview-rtl-touch-item-padding;
        }

        & .e-list-icon {
          margin-left: 12px;
        }

        & .e-back-button {
          margin-left: 12px;
          margin-right: 0;
        }
      }

      .e-listview-checkbox {
        &.e-checkbox-right {
          @if ($skin-name == 'bootstrap4') {
            margin: 0 12px 0 0;
          }
          @if ($skin-name == 'tailwind') {
            margin: $listview-touch-rtl-checkbox-right-margin;
          }
        }

        &.e-checkbox-left {
          @if ($skin-name == 'bootstrap4') {
            margin: 0 0 0 12px;
          }
          @if ($skin-name == 'tailwind') {
            margin: $listview-touch-rtl-checkbox-left-margin;
          }
        }
      }

      @if ($skin-name == 'tailwind') {
        &.e-list-template {
          .e-list-wrapper {
            &.e-list-avatar {
              .e-avatar {
                margin: 8px 16px 8px 24px;
              }
            }

            &.e-list-avatar:not(.e-list-badge) {
              padding-right: $listview-touch-template-avatar-padding-left;
            }

            &.e-list-badge.e-list-avatar {
              padding-right: $listview-touch-template-avatar-padding-left;
            }
          }
        }
      }
    }

    @if ($skin-name == 'tailwind') {
      &.e-list-template {
        .e-list-header {
          padding: 8px 16px;
        }

        .e-list-wrapper {
          &.e-list-multi-line {
            padding: $listview-touch-template-multiline-padding;

            .e-list-item-header,
            .e-list-content {
              font-size: $listview-touch-template-multiline-header-font-size;
              font-weight: $font-weight-normal;
              line-height: $listview-touch-template-multiline-header-line-height;
            }
          }

          &:not(.e-list-multi-line) {
            &.e-list-badge:not(.e-list-avatar) {
              padding: 0 12px 0 16px;

              .e-list-content {
                padding: 14px 0;
              }
            }
          }

          &.e-list-avatar {
            .e-avatar {
              height: $listview-touch-template-avatar-size;
              margin: $listview-touch-template-avatar-margin;
              width: $listview-touch-template-avatar-size;
            }
          }

          &.e-list-avatar:not(.e-list-badge) {
            padding-left: $listview-touch-template-avatar-padding-left;
          }

          &.e-list-badge.e-list-avatar {
            padding-left: $listview-touch-template-avatar-padding-left;
          }

          &:not(.e-list-multi-line) {
            .e-list-content {
              font-size: 16px;
              padding: 12px 0;
            }
          }
        }
      }
    }
    @if ($skin-name == 'FluentUI') {
      & .e-text-content.e-checkbox .e-list-text {
        width: calc(100% - 60px);
      }
    }
    @else {
      & .e-text-content.e-checkbox .e-list-text {
        width: calc(100% - 40px);
      }
    }
    @if ($skin-name == 'FluentUI') {
      &.e-list-template {
        .e-list-wrapper {
          &:not(.e-list-multi-line) {
            padding: $listview-template-touch-padding;
          }

          &.e-list-multi-line {
            padding: $listview-template-multiline-touch-padding;

            .e-list-item-header {
              font-size: $listview-template-touch-font-size;
              line-height: 24px;
            }

            .e-list-content {
              font-size: $listview-template-multiline-header-font-size;
              line-height: 22px;
            }
          }

          &.e-list-avatar {
            .e-avatar {
              height: $listview-template-avatar-touch-size;
              width: $listview-template-avatar-touch-size;
            }
          }

          &:not(.e-list-multi-line) {
            .e-list-content {
              font-size: $listview-template-touch-font-size;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  .e-listview {
    -webkit-overflow-scrolling: touch;
    border: $listview-border-size solid;
    border-radius: $listview-root-border-radius;
    display: block;
    overflow: auto;
    position: relative;
    width: 100%;

    &:not(.e-list-template) .e-list-item {
      height: $listview-item-height;
      line-height: $listview-item-line-height;
      padding: $listview-item-padding;
      position: relative;
    }

    @at-root {
      & .e-list-item {
        border-bottom: $listview-border-size solid;
        cursor: pointer;
        @if ($skin-name == 'tailwind') {
          .e-text-content .e-list-text {
            font-size: $listview-font-size;
          }
        }
      }

      & .e-list-parent {
        margin: 0;
        padding: 0;
      }

      & .e-list-header .e-text.header {
        display: none;
      }

      & .e-icon-back {
        @if ($skin-name == 'tailwind') {
          height: $listview-back-icon-size;
          width: $listview-back-icon-size;
        }
        @else {
          margin-top: $listview-icon-back-margin;
        }
        @if ($skin-name == 'FluentUI') {
          margin-right: $listview-icon-back-margin-right;
        }
      }

      & .e-list-header .e-headertemplate-text.nested-header {
        display: none;
      }

      & .e-list-header {
        align-items: center;
        display: flex;
        font-weight: $listview-header-font-weight;
        height: $listview-header-height;
        @if ($skin-name == 'tailwind') {
          padding: 6px $listview-header-text-padding;

          .e-headertext {
            text-transform: uppercase;
          }
        }
        @else {
          @if ($skin-name != 'FluentUI') {
            border-bottom: 1px solid;
          }
          padding: 0 $listview-header-text-padding;
        }
      }

      & .e-has-header > .e-view {
        top: 45px;
      }

      & .e-back-button {
        cursor: pointer;
        @if ($skin-name == 'tailwind' or $skin-name == 'FluentUI') {
          margin-right: $listview-back-padding-right;
        }
        @else {
          padding-right: $listview-back-padding-right;
        }
        @if ($skin-name == 'FluentUI') {
          font-size: 12px;
        }
      }

      & .e-list-group-item:first-child {
        border: 0;
        border-bottom: $listview-group-first-border-bottom solid $listview-border-bottom-color;
      }

      & .e-list-group-item {
        border-bottom: $listview-group-border-bottom solid $listview-border-bottom-color;
        border-top: $listview-group-border-top solid;
        height: $listview-groupheader-item-height;
        line-height: $listview-groupheader-item-line-height;
        @if ($skin-name == 'tailwind') {
          box-sizing: border-box;
          font-weight: $font-weight-medium;
          padding: $listview-header-padding;

          .e-list-text {
            text-transform: uppercase;
          }
        }
        @else {
          font-weight: 600;
          padding: $listview-item-padding;
        }

        .e-list-text {
          cursor: default;
        }
      }

      & .e-icon-collapsible {
        cursor: pointer;
        font-size: $listview-nested-icon-collapsible;
        position: absolute;
        right: 0%;
        top: 50%;
        transform: translateY(-50%);
        @if ($skin-name == 'tailwind') {
          height: $listview-icon-size;
          width: $listview-icon-size;
        }
      }

      & .e-text-content {
        height: 100%;
        position: relative;
        vertical-align: middle;
      }

      & .e-text-content * {
        display: inline-block;
        vertical-align: middle;
      }

      & .e-text-content.e-checkbox .e-list-text {
        width: calc(100% - 40px);
      }

      & .e-text-content.e-checkbox.e-checkbox-left .e-list-icon + .e-list-text {
        width: calc(100% - 90px);
      }

      & .e-text-content.e-checkbox.e-checkbox-right .e-list-icon + .e-list-text {
        width: calc(100% - 80px);
      }

      & .e-list-item.e-checklist.e-has-child {
        .e-text-content.e-checkbox.e-checkbox-right {
          .e-list-icon + .e-list-text {
            width: calc(100% - 92px);
          }
        }
      }

      & .e-checkbox .e-checkbox-left {
        margin: $listview-checkbox-left-margin;
      }

      & .e-checkbox .e-checkbox-right {
        margin: $listview-checkbox-right-margin;
      }

      & .e-list-text {
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
        width: 100%;
      }

      & .e-list-icon + .e-list-text {
        width: calc(100% - 60px);
      }

      & .e-icon-wrapper .e-list-text {
        width: calc(100% - 60px);
      }

      & .e-icon-wrapper.e-text-content.e-checkbox .e-list-text {
        width: calc(100% - 60px);
      }

      & .e-list-icon {
        margin-right: $listview-icon-margin-right;
        @if ($skin-name == 'tailwind' or $skin-name == 'FluentUI') {
          height: $listview-icon-size;
          width: $listview-icon-size;
        }
        @else {
          height: 30px;
          width: 30px;
        }
      }

      & .e-content {
        overflow: hidden;
        position: relative;
      }

      & .e-list-header.e-list-navigation .e-text {
        cursor: pointer;
      }

      & .e-list-header .e-text {
        cursor: default;
        text-indent: 0;
      }

      & .e-text .e-headertext {
        display: inline-block;
        line-height: $listview-header-line-height;
      }

      &.e-rtl {
        direction: rtl;

        @if ($skin-name == 'FluentUI') {
          & .e-back-button {
            margin-left: 12px;
            margin-right: 0;
          }
        }

        &:not(.e-list-template) .e-list-item {
          @if ($skin-name == 'tailwind') {
            padding: $listview-rtl-item-padding;
          }
        }

        & .e-list-icon {
          @if ($skin-name == 'tailwind') {
            margin-left: 8px;
          }
          @else {
            margin-left: 16px;
          }
          margin-right: 0;
        }

        & .e-icon-collapsible {
          left: 0%;
          right: initial;
          top: 50%;
          transform: translateY(-50%) rotate(180deg);
        }

        & .e-list-header .e-text {
          cursor: pointer;
        }

        & .e-back-button {
          transform: rotate(180deg);
          @if ($skin-name == 'tailwind') {
            margin-left: 8px;
            margin-right: 0;
          }
        }

        & .e-icon-back {
          @if ($skin-name != 'tailwind') {
            margin-top: $listview-rtl-icon-back-margin;
          }
        }

        & .e-checkbox .e-checkbox-left {
          margin: $listview-rtl-checkbox-left-margin;
        }

        & .e-checkbox .e-checkbox-right {
          margin: $listview-rtl-checkbox-right-margin;
        }
      }
    }
  }
}


@include export-module('listview-theme') {
  .e-listview {
    border-color: $listview-line-color;
    font-family: $listview-font-family;
    font-size: $listview-font-size;
    @at-root {
      & .e-list-header {
        background-color: $listview-header-bg;
        border-color: $listview-header-border;
        color: $listview-header-text-color;
        font-size: $listview-header-font-size;
      }

      & .e-icons {
        color: $listview-icon-color;

        @if ($skin-name == 'tailwind') {
          &.e-back-button:hover,
          &.e-icon-collapsible:hover {
            color: $icon-color-hover;
          }
        }
        @if ($skin-name == 'bootstrap5') {
          &.e-back-button:hover {
            color: $icon-color-hover;
          }

          &.e-back-button:active {
            color: $icon-color-pressed;
          }
        }
      }

      & .e-list-item {
        background-color: $listview-background;
        border-bottom: $listview-border-bottom solid $listview-border-bottom-color;
        border-left: $listview-border-left solid $listview-border-left-color;
        border-right: $listview-border-right solid $listview-border-right-color;
        border-top: $listview-border-top solid $listview-border-top-color;
        color: $listview-text-color;
      }

      & .e-list-item.e-hover,
      & .e-list-item.e-hover.e-active.e-checklist {
        background-color: $listview-item-hover-bg;
        border-color: $listview-hover-border-color;
        color: $listview-text-hover-color;
      }

      & .e-list-item.e-active {
        background-color: $listview-item-active-bg;
        color: $listview-text-active-color;
      }

      & .e-list-item.e-active.e-checklist {
        background-color: $listview-background;
        color: $listview-text-color;
      }

      & .e-list-item.e-focused,
      & .e-list-item.e-focused.e-active.e-checklist {
        background-color: $listview-item-active-bg;
        color: $listview-text-active-color;
      }

      & .e-list-item.e-focused .e-checkbox-wrapper .e-frame.e-check,
      & .e-list-item.e-focused .e-css.e-checkbox-wrapper .e-frame.e-check {
        @if ($skin-name != 'tailwind') {
          background-color: $checkmark-bgcolor;
          border-color: $checkmark-border-color;
          color: $checkmark-color;
        }
      }

      & .e-list-group-item {
        background-color: $listview-groupheader-bg;
        border-color: $listview-header-border;
        color: $listview-groupheader-text-color;
        font-size: $listview-groupheader-font-size;
      }

      @if ($skin-name == 'bootstrap4' or $skin-name == 'bootstrap5') {
        & .e-list-item.e-focused .e-icon-wrapper .e-icon-collapsible {
          color: $listview-focused-collapsible-color;
        }
      }
    }

    &.e-list-template {
      .e-list-wrapper {
        height: inherit;
        position: relative;

        &:not(.e-list-multi-line) {
          padding: $listview-template-padding;

          @if ($skin-name == 'tailwind' or $skin-name == 'bootstrap5') {
            &.e-list-badge:not(.e-list-avatar) {
              padding: 0 8px 0 12px;
            }
          }
          @if ($skin-name == 'FluentUI') {
            &.e-list-badge:not(.e-list-avatar) {
              padding: 2px 10px 2px 16px;
            }
          }
        }

        &.e-list-multi-line {
          padding: $listview-template-multiline-padding;

          .e-list-item-header {
            color: $listview-template-multiline-header-color;
            display: block;
            font-size: $listview-template-multiline-header-font-size;
            @if ($skin-name == 'tailwind' or $skin-name == 'FluentUI' or $skin-name == 'bootstrap5') {
              font-weight: $font-weight-normal;
              line-height: 22px;
            }
            @else {
              font-weight: 500;
            }
            margin: 0;
            overflow: hidden;
            padding: $listview-template-multiline-header-padding;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .e-list-content {
            color: $listview-template-multiline-content-color;
            display: block;
            font-size: $listview-template-multiline-content-font-size;
            @if ($skin-name == 'tailwind' or $skin-name == 'FluentUI' or $skin-name == 'bootstrap5') {
              font-weight: $font-weight-normal;
              @if ($skin-name == 'FluentUI') {
                line-height: 18px;
              }
              @else {
                line-height: 22px;
              }
            }
            margin: 0;
            padding: $listview-template-multiline-content-padding;
            word-wrap: break-word;
          }

          .e-list-content:not(.e-text-overflow) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &.e-list-avatar {
          .e-avatar {
            height: $listview-template-avatar-size;
            left: $listview-template-avatar-left;
            position: absolute;
            top: $listview-template-avatar-top;
            width: $listview-template-avatar-size;
            @if ($skin-name == 'tailwind' or $skin-name == 'FluentUI' or $skin-name == 'bootstrap5') {
              margin: $listview-template-avatar-margin;
            }
          }
        }

        &.e-list-avatar:not(.e-list-badge) {
          padding-left: $listview-template-avatar-padding-left;
          padding-right: $listview-template-avatar-padding-right;
        }

        &.e-list-avatar-right:not(.e-list-badge) {
          padding-left: $listview-template-avatar-rightposition-padding-left;
          padding-right: $listview-template-avatar-rightposition-padding-right;

          .e-avatar {
            height: $listview-template-avatar-size;
            position: absolute;
            right: $listview-template-avatar-rightposition-right;
            top: $listview-template-avatar-top;
            width: $listview-template-avatar-size;
          }
        }

        &.e-list-multi-line.e-list-avatar {
          .e-avatar {
            top: $listview-template-multiline-avatar-top;
          }
        }

        &.e-list-multi-line.e-list-avatar-right:not(.e-list-badge) {
          .e-avatar {
            top: $listview-template-multiline-avatar-top;
          }
        }

        &.e-list-badge {
          .e-badge {
            font-size: $listview-template-badge-font-size;
            height: $listview-template-badge-height;
            line-height: $listview-template-badge-line-height;
            padding: 0;
            position: absolute;
            right: $listview-template-badge-right;
            top: 50%;
            transform: translateY(-50%);
            width: $listview-template-badge-width;
            @if ($skin-name == 'tailwind' or $skin-name == 'FluentUI' or $skin-name == 'bootstrap5') {
              border-radius: 10px;
            }
          }
        }

        &.e-list-badge.e-list-avatar {
          padding-left: $listview-template-avatar-badge-padding-left;
          padding-right: $listview-template-avatar-badge-padding-right;
        }

        &.e-list-badge:not(.e-list-avatar) {
          padding-left: $listview-template-badgewithoutavatar-padding-left;
          padding-right: $listview-template-badgewithoutavatar-padding-right;
        }

        &:not(.e-list-multi-line) {
          .e-list-content {
            display: block;
            margin: 0;
            overflow: hidden;
            padding: $listview-template-item-padding;
            text-overflow: ellipsis;
            white-space: nowrap;
            @if ($skin-name == 'tailwind' or $skin-name == 'bootstrap5') {
              font-size: $listview-font-size;
              line-height: $listview-item-line-height;
            }
          }
        }
      }

      .e-list-item {
        &.e-focused {
          .e-list-wrapper.e-list-multi-line {
            .e-list-item-header {
              @if ($skin-name == 'bootstrap5') {
                color: $content-text-color-selected;
              }
            }

            .e-list-content {
              @if ($skin-name == 'bootstrap5') {
                color: $content-text-color-selected;
              }
            }
          }
        }

        &.e-hover .e-list-item-header {
          color: $listview-text-hover-color;
        }

        &.e-hover .e-list-content {
          color: $listview-text-hover-color;
        }

        &.e-active .e-list-item-header {
          color: $listview-text-active-color;
        }

        &.e-active .e-list-content {
          color: $listview-text-active-color;
        }
      }
    }

    &.e-rtl {
      &.e-list-template .e-list-wrapper {
        &.e-list-avatar {
          .e-avatar {
            left: inherit;
            @if ($skin-name == 'tailwind' or $skin-name == 'bootstrap5') {
              margin: 7px 12px 7px 16px;
            }
            right: $listview-template-avatar-left;
          }
        }

        &.e-list-avatar:not(.e-list-badge) {
          padding-left: $listview-template-avatar-padding-right;
          padding-right: $listview-template-avatar-padding-left;
        }

        &.e-list-avatar-right:not(.e-list-badge) {
          padding-left: $listview-template-avatar-rightposition-padding-right;
          padding-right: $listview-template-avatar-rightposition-padding-left;

          .e-avatar {
            left: $listview-template-avatar-rightposition-right;
            right: inherit;
          }
        }

        &.e-list-badge {
          .e-badge {
            left: $listview-template-badge-right;
            right: inherit;
          }
        }

        &.e-list-badge.e-list-avatar {
          padding-left: $listview-template-avatar-badge-padding-right;
          padding-right: $listview-template-avatar-badge-padding-left;
        }

        &.e-list-badge:not(.e-list-avatar) {
          padding-left: $listview-template-badgewithoutavatar-padding-right;
          padding-right: $listview-template-badgewithoutavatar-padding-left;
        }
      }
    }
  }
}


@include export-module('listview-material-dark-icons') {
  .e-listview {
    .e-icon-collapsible::before {
      content: '\e913';
    }

    .e-icon-back::before {
      content: '\e85b';
    }
  }
}

