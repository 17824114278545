
@import "./palette.scss";

$app-background-color: #1f2226;
$app-background-color-rgb: 36,46,66;
$app-background-color-contrast: #FFFFFF;
$app-background-color-shade: #1f2226;
$app-background-color-tint: #1f2226;


$sethala-orange-color: #ffb102; // Orange 700
$sethala-orange-color-rgb: 245,124,0;
$sethala-orange-color-contrast: #FFFFFF;
$sethala-orange-color-shade: #ffb102;
$sethala-orange-color-tint: #ffb102;

$primary-color: #80C343; // Custom
$primary-color-rgb: 128,195,67;
$primary-color-contrast: #FFFFFF;
$primary-color-shade: #80C343;
$primary-color-tint: #80C343;

$secondary-color: #1f2226; // Custom BlueGrey 900
$secondary-color-rgb: 55,70,87;
$secondary-color-contrast: #FFFFFF;
$secondary-color-shade: #1f2226;
$secondary-color-tint: #1f2226;

$tertiary-color: #ff5722; // Deep Orange  500
$tertiary-color-rgb: 255,87,34;
$tertiary-color-contrast: #FFFFFF;
$tertiary-color-shade: #ff5722;
$tertiary-color-tint: #ff5722;

$quaternary-color: #f57c00; // Orange 700
$quaternary-color-rgb: 245,124,0;
$quaternary-color-contrast: #FFFFFF;
$quaternary-color-shade: #f57c00;
$quaternary-color-tint: #f57c00;

$quinary-color: #E91E63; // Pink 500
$quinary-color-rgb: 233,30,99;
$quinary-color-contrast: #FFFFFF;
$quinary-color-shade: #E91E63;
$quinary-color-tint: #E91E63;



$primary : $green-500;
$primary-color : $green-500;
$primary-text: #FFFFFF;

$secondary: $blue-900;
$secondary-color: $blue-grey-900;
$secondary-text: $teal-700;

$accent:$blue-grey-900;
$accent-color: $blue-grey-900;
$accent-text: #FFFFFF;


$black-text: #1f2226;
$white-text: #FFFFFF;
$white: #FFFFFF;

$sidenav-background: #1f2226;
$content-background: #d2d2d2;


$background:#001B2C; //Primary Background color - RWS
// $background-grey: $grey-800;
$background-grey: $grey-800;

$action-title-font-size: 12px;
$action-title-font-color: $grey-color;

// Menu background color - RWS
// $breadcrumb-background: #1f2226
$breadcrumb-background: #001B2C;




