@import "mixins/material-theme.scss";
@import "variables.scss";
@import "synfusion.scss";
@import "_avatar.scss";
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import 'icons.scss';

:root{
  

  --bs-body-color: $background;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: $background;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #f8f9fa;
  --bs-emphasis-color-rgb: 248, 249, 250;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-emphasis-color: #FFFFFF;
  --bs-primary-text: #64B5F6;
  --bs-secondary-text: #dee2e6;
  --bs-success-text: #81C784;
  --bs-info-text: #4DD0E1;
  --bs-warning-text: #FFF176;
  --bs-danger-text: #E57373;
  --bs-light-text: #f8f9fa;
  --bs-dark-text: #dee2e6;
  --bs-primary-bg-subtle: #0D47A1;
  --bs-secondary-bg-subtle: #212529;
  --bs-success-bg-subtle: #1B5E20;
  --bs-info-bg-subtle: #006064;
  --bs-warning-bg-subtle: #F57F17;
  --bs-danger-bg-subtle: #B71C1C;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #1976D2;
  --bs-secondary-border-subtle: #495057;
  --bs-success-border-subtle: #388E3C;
  --bs-info-border-subtle: #00838F;
  --bs-warning-border-subtle: #F9A825;
  --bs-danger-border-subtle: #D32F2F;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: #fff;
  --bs-link-color: #64B5F6;
  --bs-link-hover-color: #90CAF9;
  --bs-link-color-rgb: 100, 181, 246;
  --bs-link-hover-color-rgb: 144, 202, 249;
  --bs-code-color: #F06292;
  --bs-border-color: #495057;
  --bs-border-color-translucent: 
}


body {
  box-sizing: border-box;
  margin: 0px;
  font-family:  "Helvetica Neue",Helvetica,Arial,sans-serif !important;
  color: white ;
  width: 100% !important;
  height: 100% !important;
  background-color: $background !important;

}

html {
  font-family:  "Helvetica Neue",Helvetica,Arial,sans-serif !important;
  width: 100% !important;
  height: 100% !important;
  background-color: $background !important;
}
.mat-drawer-inner-container::-webkit-scrollbar {
display: none;
}
body::-webkit-scrollbar {
  display: none;
  }

h1{
  font-family:  "Helvetica Neue",Helvetica,Arial,sans-serif !important;
}
h2{
  font-family:  "Helvetica Neue",Helvetica,Arial,sans-serif !important;
}
.fw {
  width: 100% !important;
}

.fh {
  height: 100% !important;
}

.box {
  border: 2px red solid !important;
}

.boxg {
  border: 2px green solid !important;
}
.boxb {
  border: 2px blue solid !important;
}

.boxp {
  border: 2px purple solid !important;
}

.no-padding{
  padding: 0px;
}
.card{
  background-color: $background !important;
}
.e-card{
  background-color: $background !important;
}
.background {
  background-color: $background !important;
}

.top-menu{
  background-color: $background !important;
}

.left-menu{
  background-color: $background !important;
  color: $white;
  overflow-y: hidden;
}

.menu-item{
  width: 100%;
  font-size: 16px !important;
}


.header-text {
    font-weight: 100;
    font-size: 28px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.link {
  text-decoration: none !important;
  color: $white !important; /* For Example */
}

::ng-deep .mat-card{
  border: 55px !important;
  border-radius: 0px !important;
  border: #000 !important;
 }

 .mat-card::-webkit-scrollbar {
  display: none !important;
  }

.main-content {
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 15px;
}


.page-header {
  background-color: #f2f2f200;
  padding: 0px;
  text-align: left;
}
.page-header h1 {
  font-size: 0px;
  margin-bottom: 0;
}

/* csslint ignore:start */
@keyframes custom-rolling {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes custom-rolling {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.custom-rolling {
  position: relative;
}

.custom-rolling div,
.custom-rolling div:after {
  border: 16px solid #51cacc;
  border-radius: 50%;
  border-top-color: transparent;
  height: 160px;
  position: absolute;
  width: 160px;
}

.custom-rolling div {
  -webkit-animation: custom-rolling 1.3s linear infinite;
  animation: custom-rolling 1.3s linear infinite;
  top: 100px;
  left: 100px;
}

.custom-rolling div:after {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.custom-rolling {
  -webkit-transform: translate(-31px, -31px) scale(0.31) translate(31px, 31px);
  height: 62px !important;
  transform: translate(-31px, -31px) scale(0.31) translate(31px, 31px);
  width: 62px !important;
}

.sideNavContent {
  color: green;
}

.secure-router-card{
  // background-color: #323436 !important; //Page Background RWS
  background-color: unset;
  background-color: #243743 !important; //Page Background RWS
}

.menu-item-text {
  margin-right: 50px !important;
  color: $grey-800;
}

.menu-item-icon{
  color: $grey-700;
}
.mat-toolbar{
  background-color: $background;
  border-bottom: 1px solid #243743 !important;
  height: 50px ;
}
.mat-sidenav , .mat-accordion{
  background-color: $background;
  border: 1px solid #243743 !important;
}
.e-accordion{
  border: 0px;
  background-color: #243743 !important; //Page Background RWS

}
.control-section{
  background-color: $background;
}
.e-accordion .e-acrdn-item.e-select.e-active>.e-acrdn-header .e-acrdn-header-content,
.e-accordion .e-acrdn-item.e-select.e-item-focus>.e-acrdn-header .e-acrdn-header-content {
    color: $white !important;
}

// Menu background color - RWS
.e-accordion .e-acrdn-item {
  text-align: left;
  // background-color: $breadcrumb-background;
  background-color: #001B2C !important; 
  padding-top: 2px;
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  color: $white;

}





// RWS

.mat-card{
  background-color: unset;
  background-color: #001B2C !important;
}


.e-grid .e-groupdroparea {
  background-color: unset;
  background-color: #000000;
  background-color: unset;
  // border-top-color: #ffffff;
  color: rgba(255, 255, 255, 0.7);
  }

//Buttons
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #EC6608;
  }

  .mat-label.mat-primary {
    color: #EC6608;
  }

// Table Top text
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text .e-anchor-wrap {
  align-items: inherit;
  display: inherit;
  color: #EC6608;

  }

// Table sort
.e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop):not(.e-icon-reorderuparrow):not(.e-icon-reorderdownarrow) {
  color: #EC6608;
  }

// Table highlights
// Footer

.e-pager .e-currentitem.e-numericitem.e-focused {
  background: #EC6608;
  box-shadow: none;
  }
.e-pager .e-currentitem, .e-pager .e-currentitem:hover {
    background: #EC6608;
    opacity: 1;
}
.e-input-group:not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
  border-color: #6c757d;
  }

.e-pager div.e-icons.e-pager-default {
  color: #EC6608;
  }

.e-pager .e-spacing.e-numericitem:not(.e-active):not(.e-np):not(.e-pp) {
  color: #EC6608;
  opacity: 1;
  }

  .mat-button .mat-button-wrapper>*, .mat-flat-button .mat-button-wrapper>*, .mat-stroked-button .mat-button-wrapper>*, .mat-raised-button .mat-button-wrapper>*, .mat-icon-button .mat-button-wrapper>*, .mat-fab .mat-button-wrapper>*, .mat-mini-fab .mat-button-wrapper>* {
    vertical-align: middle;
    color: #EC6608;
    }

    .e-footer-content .e-btn.e-primary.e-flat:not([DISABLED]) {
      background-color: #EC6608;
      }

      .e-pager .e-currentitem, .e-pager .e-currentitem:hover {
        background-color: #EC6608;
        color: #000000;
        opacity: 1;
        }
        .e-pager .e-numericitem:hover {
          color: #EC6608;
          opacity: 1;
          }
          .e-pager .e-numericitem {
            color: #EC6608;
            border-color: #EC6608;
            opacity: 1;
            }

// Progress bar
.mat-progress-bar-buffer {
  background-color: #EC6608;
  }
            

// Refresh Button
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-tbar-btn-text {
  color: #ffffff;
  background-color: #001B2C;
  }
  .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn:hover .e-tbar-btn-text {
    color: #ffffff;
    background-color: #EC6608;

    }

// Filter - OK Button
.e-footer-content .e-btn:hover.e-primary.e-flat:not([DISABLED]) {
  background-color: #ff6a00;
  }      
.e-footer-content .e-btn.e-primary.e-flat:not([DISABLED]) {
  background-color: #EC6608;
  border-color: #EC6608;
  color: #FFFFFF;
  }

// Filter Cancel button
.e-footer-content .e-btn:hover.e-flat:not([DISABLED]) {
  background-color: #1f1f1f;
  }
.e-footer-content .e-btn.e-flat:not([DISABLED]) {
  background-color: #1e1e1e;
  border-color: #1e1e1e;
  }

  .e-grid .e-groupdroparea.e-grouped {
    background-color: $background;
}
// Selected in filter
  .e-contextmenu-wrapper ul .e-menu-item.e-selected, .e-contextmenu-container ul .e-menu-item.e-selected {
    background-color: #EC6608;
    color: #FFFFFF;
    outline: 0 solid #EC6608;
    outline-offset: 0;
    }

    .e-pager .e-currentitem, .e-pager .e-currentitem {
      background: #EC6608;
      border-color: #EC6608;
      color: #000000;
      opacity: 1;
      }

      .e-pager .e-currentitem, .e-pager .e-currentitem:hover {
        background: #EC6608;
        border-color: #EC6608;
        color: #000000;
        opacity: 1;
        }

    .e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input, .e-float-input input, .e-float-input.e-control-wrapper input, .e-input-group textarea.e-input, .e-input-group.e-control-wrapper textarea.e-input, .e-float-input textarea, .e-float-input.e-control-wrapper textarea, .e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled input.e-input, .e-input-group.e-control-wrapper.e-disabled input.e-input, .e-input-group.e-disabled textarea.e-input, .e-input-group.e-control-wrapper.e-disabled textarea.e-input {
      border-color: #EC6608;
      color: inherit;
      }


  // Checkbox
  .e-checkbox-wrapper:hover .e-frame.e-check, .e-css.e-checkbox-wrapper:hover .e-frame.e-check {
    background-color: #EC6608;
    border-color: #EC6608;
    color: #FFFFFF;
    }
    // Checkbox
    .e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check {
      background-color: #EC6608;
      border-color: #EC6608;
      color: #FFFFFF;
      }

      .e-checkbox-wrapper:hover .e-frame.e-stop, .e-css.e-checkbox-wrapper:hover .e-frame.e-stop {
        background-color: #EC6608;
        border-color: #EC6608;
        }
        .e-checkbox-wrapper .e-frame.e-stop, .e-css.e-checkbox-wrapper .e-frame.e-stop {
          background-color: #EC6608;
          border-color: #EC6608;
          }

// Dashboard
// Top buttons
.btn:hover {
  color: #ffffff;
  background-color: #EC6608;
  border-color: #EC6608;
}
.btn {
  color: #ffffff;
  background-color: #001B2C;
  border-color: #001B2C;
}

.e-btn:hover, .e-css.e-btn:hover {
  background-color: #EC6608;
  border-color: #EC6608;
  box-shadow: none;
  color: #FFFFFF;
  }

  .e-btn, .e-css.e-btn {
    background-color: #001B2C;
    border-color: #001B2C;
    box-shadow: none;
    color: #FFFFFF;
    }


    .e-btn:active, .e-css.e-btn:active {
      background-color: #001B2C;
      border-color: transparent;
      color: #fff;
      outline: #616161 0 solid;
      outline-offset: 0;
      box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2);
      }

// Top Containers


// Tabs
.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-text, .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-icon {
  color: #ffffff;
  }

  .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text, .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-icon {
    color: #EC6608;
    }

    .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
      // border: 1px solid #EC6608;
      color: #EC6608;

      }
// Switch
.e-switch-wrapper .e-switch-inner.e-switch-active .e-switch-on, .e-css.e-switch-wrapper .e-switch-inner.e-switch-active .e-switch-on {
  background-color: #EC6608;
  border-color: #EC6608;
  color: #FFFFFF;
  }
  .e-switch-wrapper:hover .e-switch-inner.e-switch-active .e-switch-on, .e-css.e-switch-wrapper:hover .e-switch-inner.e-switch-active .e-switch-on {
    background-color: #EC6608;
    color: #FFFFFF;
    border-color: #EC6608;
    }

    // Alerts
    .e-listview.e-list-template .e-list-wrapper.e-list-multi-line .e-list-content:not(.e-text-overflow) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      }