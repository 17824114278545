//@import './../../../node_modules/@syncfusion/ej2-base/styles/material.css';
//@import './../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
//@import '../../../node_modules/@syncfusion/ej2-angular-grids/styles/bootstrap5-dark.scss';

@import './../../../node_modules/@syncfusion/ej2-base/styles/bootstrap5-dark.scss';
@import './../../../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.scss';

//@import './syncfusion/base/base.scss';

@import './../../../node_modules/@syncfusion/ej2-layouts/styles/bootstrap5-dark.scss';
@import '../../../node_modules/@syncfusion/ej2-angular-grids/styles/bootstrap5-dark.scss';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5-dark.scss';
@import '../../../node_modules/@syncfusion/ej2-angular-buttons/styles/bootstrap5-dark.scss';
@import '../../../node_modules/@syncfusion/ej2-angular-navigations/styles/bootstrap5-dark.scss';
@import '../../../node_modules/@syncfusion/ej2-angular-base/styles/bootstrap5-dark.scss';
@import '../../../node_modules/@syncfusion/ej2-angular-calendars/styles/bootstrap5-dark.scss';
@import '../../../node_modules/@syncfusion/ej2-angular-dropdowns/styles/bootstrap5-dark.scss';
@import '../../../node_modules/@syncfusion/ej2-angular-inputs/styles/bootstrap5-dark.scss';
@import '../../../node_modules/@syncfusion/ej2-angular-layouts/styles/bootstrap5-dark.scss';
@import '../../../node_modules/@syncfusion/ej2-angular-querybuilder/styles/bootstrap5-dark.scss';
@import '../../../node_modules/@syncfusion/ej2-base/styles/bootstrap5-dark.scss';
@import '../../../node_modules/@syncfusion/ej2-angular-notifications/styles/bootstrap5-dark.scss';
@import '../../../node_modules/@syncfusion/ej2-angular-buttons/styles/bootstrap5-dark.scss';
@import '../../../node_modules/@syncfusion/ej2-angular-popups/styles/bootstrap5-dark.scss';
@import '../../../node_modules/@syncfusion/ej2-angular-lists/styles/bootstrap5-dark.scss';
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");

@import "./syncfusion/dropdowns/drop-down-base.scss";
@import "./syncfusion/dropdowns/auto-complete.scss";
@import "./syncfusion/dropdowns/drop-down-list.scss";

@import "./syncfusion/calendars/calendar.scss";
@import "./syncfusion/calendars/datepicker.scss";
@import "./syncfusion/calendars/datetimepicker.scss";

/*! breadcrumb icons */
.e-breadcrumb .e-breadcrumb-collapsed::before,
.e-breadcrumb .e-breadcrumb-menu::before {
  content: "\eb04";
}
.e-breadcrumb .e-home {
  margin-top: -1px;
}
.e-breadcrumb .e-home::before {
  content: "\e92b";
}

.e-bigger .e-breadcrumb .e-home,
.e-bigger.e-breadcrumb .e-home {
  margin-top: 0;
}

/*! breadcrumb layout */
.e-breadcrumb {
  display: block;
}
.e-breadcrumb.e-breadcrumb-wrap-mode {
  display: -ms-flexbox;
  display: flex;
}
.e-breadcrumb .e-breadcrumb-first-ol {
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding-right: 0;
}
.e-breadcrumb .e-breadcrumb-wrapped-ol {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
  padding-left: 0;
}
.e-breadcrumb.e-breadcrumb-scroll-mode {
  line-height: 26px;
  overflow: auto;
}
.e-breadcrumb ol {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: normal;
  margin: 0;
  padding: 0;
  padding-left: 0;
  padding-right: 0;
}
.e-breadcrumb li {
  list-style-type: none;
  white-space: nowrap;
}
.e-breadcrumb .e-breadcrumb-item {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text {
  padding: 4px 8px;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text .e-anchor-wrap {
  -ms-flex-align: inherit;
      align-items: inherit;
  display: inherit;
}
.e-breadcrumb .e-breadcrumb-item a.e-breadcrumb-text {
  cursor: pointer;
  text-decoration: none;
}
.e-breadcrumb .e-breadcrumb-item a.e-breadcrumb-text:hover {
  text-decoration: underline;
}
.e-breadcrumb .e-breadcrumb-item a.e-breadcrumb-text:focus {
  text-decoration: underline;
}
.e-breadcrumb .e-breadcrumb-item a.e-breadcrumb-text:active {
  text-decoration: underline;
}
.e-breadcrumb .e-breadcrumb-item span.e-breadcrumb-text {
  cursor: default;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-icon {
  font-size: 14px;
  padding-right: 8px;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-icon:hover {
  text-decoration: none;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-icon::before {
  display: inline-block;
  text-decoration: none;
}
.e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-icon {
  padding: 2px 0;
}
.e-breadcrumb .e-breadcrumb-item.e-disabled {
  opacity: 1;
  pointer-events: none;
}
.e-breadcrumb .e-breadcrumb-separator {
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  padding: 4px 3px;
}
.e-breadcrumb .e-breadcrumb-separator + .e-breadcrumb-separator {
  margin-left: -4px;
  padding-left: 0;
}
.e-breadcrumb.e-rtl .e-breadcrumb-separator + .e-breadcrumb-separator {
  margin-left: 0;
  margin-right: -4px;
  padding-left: 3px;
  padding-right: 0;
}
.e-breadcrumb .e-breadcrumb-collapsed,
.e-breadcrumb .e-breadcrumb-menu {
  cursor: pointer;
  font-size: 16px;
  padding: 4px 4px;
  vertical-align: bottom;
}
.e-breadcrumb .e-breadcrumb-menu {
  display: inline-block;
}
.e-breadcrumb .e-breadcrumb-item-wrapper {
  display: -ms-flexbox;
  display: flex;
}
.e-breadcrumb.e-icon-right .e-breadcrumb-icon,
.e-breadcrumb .e-icon-right .e-breadcrumb-icon, .e-breadcrumb.e-rtl .e-breadcrumb-icon {
  padding-left: 8px;
  padding-right: 0;
}
.e-breadcrumb.e-rtl.e-icon-right .e-breadcrumb-icon, .e-breadcrumb.e-rtl .e-icon-right .e-breadcrumb-icon {
  padding-left: 0;
  padding-right: 8px;
}
.e-breadcrumb.e-disabled {
  opacity: 1;
}
.e-breadcrumb.e-disabled .e-breadcrumb-item,
.e-breadcrumb.e-disabled .e-breadcrumb-separator,
.e-breadcrumb.e-disabled .e-breadcrumb-collapsed,
.e-breadcrumb.e-disabled .e-breadcrumb-menu {
  pointer-events: none;
}

.e-breadcrumb-popup {
  border: none;
  border-radius: 0;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  position: absolute;
}
.e-breadcrumb-popup ul {
  margin: 0;
  padding: 0;
}
.e-breadcrumb-popup .e-breadcrumb-item {
  list-style-type: none;
  white-space: nowrap;
}
.e-breadcrumb-popup .e-breadcrumb-item .e-breadcrumb-text {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  width: 100%;
}
.e-breadcrumb-popup .e-breadcrumb-item .e-breadcrumb-text .e-anchor-wrap {
  -ms-flex-align: inherit;
      align-items: inherit;
  display: inherit;
  width: inherit;
}
.e-breadcrumb-popup .e-breadcrumb-item .e-breadcrumb-icon {
  font-size: 14px;
  padding-right: 8px;
}
.e-breadcrumb-popup .e-breadcrumb-item.e-icon-item .e-breadcrumb-icon {
  padding: 0;
}
.e-breadcrumb-popup .e-breadcrumb-item a.e-breadcrumb-text {
  text-decoration: none;
}
.e-breadcrumb-popup.e-icon-right .e-breadcrumb-icon,
.e-breadcrumb-popup .e-icon-right .e-breadcrumb-icon, .e-breadcrumb-popup.e-rtl .e-breadcrumb-icon {
  padding-left: 8px;
  padding-right: 0;
}
.e-breadcrumb-popup.e-icon-right .e-breadcrumb-icon,
.e-breadcrumb-popup .e-icon-right .e-breadcrumb-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-pack: end;
      justify-content: flex-end;
}
.e-breadcrumb-popup.e-rtl.e-icon-right .e-breadcrumb-icon, .e-breadcrumb-popup.e-rtl .e-icon-right .e-breadcrumb-icon {
  padding-left: 0;
  padding-right: 8px;
}

.e-bigger.e-breadcrumb-popup .e-breadcrumb-text,
.e-bigger .e-breadcrumb-popup .e-breadcrumb-text {
  font-size: 16px;
  height: 48px;
  line-height: 48px;
}
.e-bigger.e-breadcrumb-popup .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup .e-breadcrumb-text .e-breadcrumb-icon {
  font-size: 16px;
  padding-right: 8px;
}
.e-bigger.e-breadcrumb-popup .e-icon-item .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup .e-icon-item .e-breadcrumb-text .e-breadcrumb-icon {
  padding: 0;
}
.e-bigger.e-breadcrumb-popup.e-icon-right .e-breadcrumb-icon,
.e-bigger.e-breadcrumb-popup .e-icon-right .e-breadcrumb-icon, .e-bigger.e-breadcrumb-popup.e-rtl .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup.e-icon-right .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup .e-icon-right .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup.e-rtl .e-breadcrumb-icon {
  padding-left: 8px;
  padding-right: 0;
}
.e-bigger.e-breadcrumb-popup.e-rtl.e-icon-right .e-breadcrumb-icon, .e-bigger.e-breadcrumb-popup.e-rtl .e-icon-right .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup.e-rtl.e-icon-right .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup.e-rtl .e-icon-right .e-breadcrumb-icon {
  padding-left: 0;
  padding-right: 8px;
}

.e-bigger.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text,
.e-bigger .e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text {
  padding: 4px 12px;
}
.e-bigger.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text,
.e-bigger .e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text {
  font-size: 16px;
}
.e-bigger.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text .e-breadcrumb-icon {
  font-size: 16px;
  padding-right: 8px;
}
.e-bigger.e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text .e-breadcrumb-icon {
  padding: 2px 0;
}
.e-bigger.e-breadcrumb .e-breadcrumb-separator,
.e-bigger .e-breadcrumb .e-breadcrumb-separator {
  font-size: 16px;
  padding: 4px 5px;
}
.e-bigger.e-breadcrumb .e-breadcrumb-separator + .e-breadcrumb-separator,
.e-bigger .e-breadcrumb .e-breadcrumb-separator + .e-breadcrumb-separator {
  padding-left: 0;
}
.e-bigger.e-breadcrumb.e-rtl .e-breadcrumb-separator + .e-breadcrumb-separator,
.e-bigger .e-breadcrumb.e-rtl .e-breadcrumb-separator + .e-breadcrumb-separator {
  padding-left: 5px;
}
.e-bigger.e-breadcrumb .e-breadcrumb-collapsed,
.e-bigger.e-breadcrumb .e-breadcrumb-menu,
.e-bigger .e-breadcrumb .e-breadcrumb-collapsed,
.e-bigger .e-breadcrumb .e-breadcrumb-menu {
  font-size: 18px;
  padding: 5px 5px;
}
.e-bigger.e-breadcrumb.e-icon-right .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger.e-breadcrumb .e-icon-right .e-breadcrumb-text .e-breadcrumb-icon, .e-bigger.e-breadcrumb.e-rtl .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb.e-icon-right .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb .e-icon-right .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb.e-rtl .e-breadcrumb-text .e-breadcrumb-icon {
  padding-left: 8px;
  padding-right: 0;
}
.e-bigger.e-breadcrumb.e-rtl .e-icon-right .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb.e-rtl .e-icon-right .e-breadcrumb-text .e-breadcrumb-icon {
  padding-left: 0;
  padding-right: 8px;
}

/*! breadcrumb theme */
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text {
  color: $white;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text:hover, .e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text:focus {
  color: $white;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text:active {
  color: $white;
}
.e-breadcrumb .e-breadcrumb-item.e-icon-item:hover {
  background-color: #f5f5f5;
  color: $white;
}
.e-breadcrumb .e-breadcrumb-item.e-icon-item:active {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.87);
}
.e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text:focus {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}
.e-breadcrumb .e-breadcrumb-item[data-active-item] .e-breadcrumb-text {
  color: $white;
  font-weight: 500;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-icon {
  color: $white;
}
.e-breadcrumb .e-breadcrumb-separator {
  color: $white;
}
.e-breadcrumb .e-breadcrumb-collapsed,
.e-breadcrumb .e-breadcrumb-menu {
  color: rgba(0, 0, 0, 0.54);
}
.e-breadcrumb .e-breadcrumb-collapsed:hover,
.e-breadcrumb .e-breadcrumb-menu:hover {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}
.e-breadcrumb .e-breadcrumb-collapsed:active,
.e-breadcrumb .e-breadcrumb-menu:active {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.87);
}
.e-breadcrumb .e-breadcrumb-collapsed:focus,
.e-breadcrumb .e-breadcrumb-menu:focus {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}
.e-breadcrumb.e-disabled .e-breadcrumb-item .e-breadcrumb-text,
.e-breadcrumb .e-disabled.e-breadcrumb-item .e-breadcrumb-text, .e-breadcrumb.e-disabled .e-breadcrumb-separator {
  color: rgba(0, 0, 0, 0.26);
}
.e-breadcrumb.e-disabled .e-breadcrumb-item .e-breadcrumb-text .e-breadcrumb-icon,
.e-breadcrumb .e-disabled.e-breadcrumb-item .e-breadcrumb-text .e-breadcrumb-icon, .e-breadcrumb.e-disabled .e-breadcrumb-separator .e-breadcrumb-icon {
  color: rgba(0, 0, 0, 0.26);
}

.e-breadcrumb-popup {
  background-color: #fff;
}
.e-breadcrumb-popup .e-breadcrumb-text {
  color: #616161;
}
.e-breadcrumb-popup .e-breadcrumb-text:hover {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.87);
}
.e-breadcrumb-popup .e-breadcrumb-item .e-breadcrumb-text:focus {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.87);
}

/* !componenticons */

.e-icon-check::before {
  content: '\e7ff';
}

#{&}.e-grid,
.e-grid-menu {
  .e-icon-gdownarrow::before {
    content: '\e83d';
  }

  .e-icon-grightarrow::before {
    content: '\e859';
  }

}
_:-ms-fullscreen, :root .highcontrast .e-userimg.sf-icon-Male::before,
_:-ms-fullscreen, :root .highcontrast .e-userimg.sf-icon-FeMale::before{
  color: black;
}
.mat-padding{
  padding: 1em;
}
h2{
  margin: unset;
}
#Emptext{
left: 41px;
position: relative;
}
#overviewgrid.e-rtl #Emptext{
  position: relative;
}

.empimg{
height: 27px;
width: 29px;
border-radius: 14px;
background-color:#e3165b;
}

.e-userimg.sf-icon-Male::before,
.e-userimg.sf-icon-FeMale::before{ 
font-size: 13px;
}

.e-userimg.sf-icon-Male, .e-userimg.sf-icon-FeMale {  
line-height: 26px;
color: white; 
font-size: 13px;
}


.e-userimg.sf-icon-Male::before{
  content: '\e700';      
}
.e-userimg.sf-icon-FeMale::before{
  content: '\e701';      
}
 #overviewgrid td.e-rowcell.e-selectionbackground.e-active {
  background-color: transparent;
  color: white;
}

.e-bigger #overviewgrid .statustxt{
  position: relative;
  line-height: 18px;
  font-size: 11px;
  font-weight: 500;
}

.rating .star {
  font-size: 132%;
  color: lightgrey;
}

.rating .star.checked {
  color: #ffa600;
}

.rating .star:before {
  content: '★';
}

#overviewgrid ::ng-deep td.e-rowcell.e-selectionbackground.e-active {
  background-color: transparent;
}

.e-image {
  height: 13px;
  width: 14px;
}

td.e-rowcell .statustxt.e-activecolor {
  color: #00cc00; //Background color of active status
  position: relative;
  top: 9px;
}

.bootstrap5 td.e-rowcell .statustxt.e-inactivecolor,
.bootstrap5 td.e-rowcell .statustxt.e-activecolor {
  top: 8px;
}

.bootstrap5 .e-checkboxfiltertext .statustemp .statustxt.e-activecolor,
.bootstrap5 .e-checkboxfiltertext .statustemp .statustxt.e-inactivecolor {
  position: relative;
  top: 1.5px;
}

.e-bigger.bootstrap5 .e-checkboxfiltertext .statustemp .statustxt.e-activecolor,
.e-bigger.bootstrap5 .e-checkboxfiltertext .statustemp .statustxt.e-inactivecolor {
  top: -0.5px;
}

.bootstrap5 .e-checkboxfiltertext #Trusttext {
  margin-left: -4px;
}

td.e-rowcell .statustxt.e-inactivecolor {
  color: #e60000;
  position: relative;
  top: 9px;
}

.statustemp.e-inactivecolor {
  background-color: #ffd7cc;
  width: 100px;
}

.statustemp.e-activecolor {
  background-color: #ccffcc;
  width: 100px;
  text-align: center;
}

.statustxt.e-activecolor {
  color: #00cc00; 
  position: relative;
  top: 9px;   
}

.statustxt.e-inactivecolor {
    color: #e60000; 
    position: relative;
    top: 9px; 
}
.statustemp {
  position: relative;
  height: 19px;
  border-radius: 5px;
  text-align: center
}
.myProgress {
    position: relative;
    height: 18px;
    width: 10em;
    text-align: left;
    background-color: black;
  }
  .myBar {
    position: absolute;
    width: 10%;
    height: 100%;
    background-color: #00b300;
  }
  .myBar.empty {
    background-color: #df2222;
  }
  .myBar.full {
    background-color: #00b300;
  }
  .myBar.warning {
    background-color: #b38300;
  }
  .myBar.unkwnown {
    background-color: #4b4b4b;
  }
  .barlabel {
    position: relative;
    left:10px;
    line-height: 18px;
    font-size: 11px;
    font-weight: 500;
    color: white;
  }

  .example-form {
    min-width: 150px;
    width: 100%;
  }
  
  .example-full-width {
    width: 100%;
  }
  
  td {
    padding-right: 8px;
  }

.example-full-width {
  width: 100%;
}

td {
  padding-right: 8px;
}
.dialog-content{
    min-width: 1200px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 5px;
}
::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bs-info,grey); 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--bs-primary,grey); 
}
.dropdown-toggle::after {
  display:none;
}

.logo-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.logo-container img {
  height: 40px;
  margin: 0;
}
.e-rule-field{
  background-color: unset;
}
.e-query-builder .e-rule-container {
  background-color: unset;
  //border-color: #444c54;
}
.e-input{
  background-color: unset;
}
.e-input-group, .e-input-group.e-control-wrapper, .e-float-input, .e-float-input.e-input-group, .e-float-input.e-control-wrapper, .e-float-input.e-input-group.e-control-wrapper {
  /* background: #212529; */
  background-color: unset;
}
input.e-input, .e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input, .e-input-group textarea.e-input, .e-input-group.e-control-wrapper textarea.e-input, textarea.e-input, .e-float-input input, .e-float-input.e-control-wrapper input, .e-float-input textarea, .e-float-input.e-control-wrapper textarea {
  background: unset;
}
