@import "../../../../node_modules/@angular/material/theming";

$sethala-green: (
    50 : #f0f8e8,
    100 : #d9edc7,
    200 : #c0e1a1,
    300 : #a6d57b,
    400 : #93cc5f,
    500 : #80c343,
    600 : #78bd3d,
    700 : #6db534,
    800 : #63ae2c,
    900 : #50a11e,
    A100 : #e7ffda,
    A200 : #c6ffa7,
    A400 : #a5ff74,
    A700 : #95ff5a,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$sethala-tertiary: (
    50 : #ffebe4,
    100 : #ffcdbd,
    200 : #ffab91,
    300 : #ff8964,
    400 : #ff7043,
    500 : #ed9f3c,
    600 : #ff4f1e,
    700 : #ffb102,
    800 : #ff3c14,
    900 : #ff2c0c,
    A100 : #ffffff,
    A200 : #fff7f6,
    A400 : #ffc9c3,
    A700 : #ffb2a9,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #ffffff,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



@font-face {
	font-family: "Work Sans";
	src: url("/assets/font/work-sans-v4-latin-regular.eot"),
	url("/assets/font/work-sans-v4-latin-regular.woff2") format("woff2"),
	url("/assets/font/work-sans-v4-latin-regular.woff") format("woff"),
	url("/assets/font/work-sans-v4-latin-regular.ttf") format("truetype"),
	url("/assets/font/work-sans-v4-latin-regular.svg#WorkSans") format("svg");
	font-weight: 300;
	font-style: normal;
}

@include mat-core;

$app-default: mat-palette($sethala-green, 500);
$app-default-accent: mat-palette($sethala-tertiary, 700);
$app-default-warn: mat-palette($mat-red, 500);

$app-default-theme: mat-dark-theme($app-default, $app-default-accent, $app-default-warn);

$custom-typography: mat-typography-config(
  $font-family: 'Roboto, Segoe UI, GeezaPro, monospace',
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(14px, 24px, 500)
);

@include mat-base-typography($custom-typography);
@include mat-checkbox-typography($custom-typography);
@include angular-material-typography($custom-typography);
@include mat-core($custom-typography);


@include angular-material-theme($app-default-theme);


.alternate-material-scheme {
	$primary: mat-palette($mat-green, 600);
	$accent: mat-palette($mat-deep-orange, 700);
	$warn: mat-palette($mat-red, 500);
	$alt-theme: mat-dark-theme($primary, $accent);

	//@include angular-material-theme($alt-theme);


}


